import React, { useState, useRef, useEffect } from "react"
import logoQ from "../images/logos/quilmesLogo.svg"
import { navigate } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styles from "../styles/ageGate.module.css"
import SEO from "../components/seo"

const AgeGate = ({ data, location }) => {
  let ageImg = data.ageImage.childImageSharp.fluid
  const inputDay = useRef(null)
  const inputYear = useRef(null)
  const inputMonth = useRef(null)
  const [remember, setRemember] = useState(false)

  const [step, setStep] = useState("")

  useEffect(() => {

    window.dataLayer.push({
      brand: "Quilmes",
      Zone: "SAZ",
      country: "ARG",
      city: "CABA",
      campaign: `${
        window.location.search.includes("?cmp=")
          ? window.location.search.split("?cmp=")[1].split("&")[0]
          : ""
      }`,
      cms: "0",
      event: "pageView",
      language: "es",
      login: false,
      pageName: "Age Gate",
      pageType: "Basic page",
      SiteType: "Brand",
      product: "NA",
      sku: "NA",
      userUid: "",
      url: "https://www.quilmes.com.ar/agegate",
      url_campaign: `${
        window.location.search.includes("utm_campaign=")
          ? window.location.search.split("utm_campaign=")[1]
          : ""
      }`,
      Step_number: "",
    })
  }, [])

  useEffect(() => {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        onsubmit()
        event.preventDefault()
      }
    }
    document.addEventListener("keydown", listener)
    return () => {
      document.removeEventListener("keydown", listener)
    }
  })

  let newPath = location.state
    ? location.state.newPath
      ? location.state.newPath
      : "/" + location.search
    : "/" + location.search

  const calculateAge = birthday => {
    let ageDate = new Date(Date.now() - birthday.getTime())
    return Math.abs(ageDate.getUTCFullYear() - 1970)
  }

  let [date, setDate] = useState({ dd: "", mm: "", aaaa: "" })

  const onChange = e => {
    let { name, value } = e.target
    if (e.target.name === "dd" && value.length >= 2) {
      value = value.slice(0, 2)
      inputMonth.current.focus()
    } else if (e.target.name === "mm") {
      if (value.length >= 2) {
        value = value.slice(0, 2)
        inputYear.current.focus()
      } else if (!value.length) {
        inputDay.current.focus()
      }
    } else {
      if (value.length > 4) value = value.slice(0, 4)
      else if (!value.length) inputMonth.current.focus()
    }
    setDate({ ...date, [name]: value })
  }

  const onsubmit = () => {
    window.dataLayer.push({
      event: "GAEvent",
      event_category: "Age Gate",
      event_action: "Intercation",
      event_label: "Yes",
      interaction: false,
      component_name: "",
      element_text: "",
    })
    remember
      ? localStorage.setItem("age", "true")
      : sessionStorage.setItem("age", "true")
    // console.log(true)
    navigate(newPath, { replace: true })
    // if (date.aaaa.length === 4 && date.dd && date.mm) {
    //   // console.log("if")
    //   let birthday = new Date()
    //
    //   birthday.setDate(parseInt(date.dd))
    //   birthday.setMonth(parseInt(date.mm) - 1)
    //   birthday.setFullYear(parseInt(date.aaaa))
    //   window.dataLayer.push({
    //     event: "GAEvent",
    //     event_category: "Age Gate",
    //     event_action: "Intercation",
    //     event_label: "Send",
    //     interaction: false,
    //     component_name: "",
    //     element_text: "",
    //   })
    //
    //   if (date.aaaa && date.mm && date.dd) {
    //     if (calculateAge(birthday) >= 18) {
    //       // console.log("birthday")
    //       window.dataLayer.push({
    //         event: "GAEvent",
    //         event_category: "Age Gate",
    //         event_action: "Intercation",
    //         event_label: "Yes",
    //         interaction: false,
    //         component_name: "",
    //         element_text: "",
    //       })
    //       remember
    //         ? localStorage.setItem("age", "true")
    //         : sessionStorage.setItem("age", "true")
    //       // console.log(true)
    //       navigate(newPath, { replace: true })
    //     } else {
    //       window.dataLayer.push({
    //         event: "GAEvent",
    //         event_category: "Age Gate",
    //         event_action: "Intercation",
    //         event_label: "No",
    //         interaction: false,
    //         component_name: "full_age_gate",
    //         element_text: "Full Age gate",
    //       })
    //       window.location.href =
    //         "https://www.youtube.com/playlist?list=PLlqVfYsWVI67zHdFAILr_cr4g2NwcobOq"
    //     }
    //   }
    // }
  }

  const onCancel = () => {
    window.dataLayer.push({
      event: "GAEvent",
      event_category: "Age Gate",
      event_action: "Intercation",
      event_label: "No",
      interaction: false,
      component_name: "full_age_gate",
      element_text: "Full Age gate",
    })
    window.location.href =
      "https://www.youtube.com/playlist?list=PLlqVfYsWVI67zHdFAILr_cr4g2NwcobOq"
  }

  return (
    <div className={styles.container}>
      <SEO title={"Quilmes"} description={""} />
      <BackgroundImage className={styles.homeImg} fluid={ageImg}>
        <div className={styles.blue_overlay}>
          <div className="row">
            <div className="col">
              <img src={logoQ} alt="logo" className={styles.logoQ} />
            </div>
          </div>

          <div className="row">
            <div className="col 2">
              <h1 className={styles.age_question}>¿SOS MAYOR DE 18 AÑOS Y CAMPEÓN DEL MUNDO?</h1>
              {/*<p className={styles.age_subtitle}>*/}
              {/*  INGRESÁ TU FECHA DE NACIMIENTO*/}
              {/*</p>*/}
            </div>
          </div>

          <div className="column">
            <div className={styles.buttons}>
              <button className={styles.button} onClick={onsubmit}>SI</button>
              <button className={styles.button} onClick={onCancel}>NO</button>
            </div>
            {/*<div className={styles.inputs}>*/}
            {/*  <input*/}
            {/*    maxLength={2}*/}
            {/*    name={"dd"}*/}
            {/*    onChange={onChange}*/}
            {/*    placeholder="DD"*/}
            {/*    value={date.dd}*/}
            {/*    ref={inputDay}*/}
            {/*    type="number"*/}
            {/*    pattern="\d*"*/}
            {/*    onFocus={() => {*/}
            {/*      setStep(1)*/}
            {/*    }}*/}
            {/*  />*/}
            {/*  <input*/}
            {/*    maxLength={2}*/}
            {/*    name={"mm"}*/}
            {/*    onChange={onChange}*/}
            {/*    placeholder="MM"*/}
            {/*    value={date.mm}*/}
            {/*    ref={inputMonth}*/}
            {/*    type="number"*/}
            {/*    pattern="\d*"*/}
            {/*    onFocus={() => {*/}
            {/*      setStep(2)*/}
            {/*    }}*/}
            {/*  />*/}
            {/*  <input*/}
            {/*    maxLength={4}*/}
            {/*    name={"aaaa"}*/}
            {/*    onChange={onChange}*/}
            {/*    placeholder="AAAA"*/}
            {/*    value={date.aaaa}*/}
            {/*    ref={inputYear}*/}
            {/*    type="number"*/}
            {/*    pattern="\d*"*/}
            {/*    onFocus={() => {*/}
            {/*      setStep(3)*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</div>*/}
            {/*<div className={styles.button_container}>*/}
            {/*  <button*/}
            {/*    className={styles.buttons_age}*/}
            {/*    onClick={onsubmit}*/}
            {/*    onKeyPress={onsubmit}*/}
            {/*  >*/}
            {/*    INGRESAR*/}
            {/*  </button>*/}
            {/*</div>*/}
          </div>
          <div className="row">
            <div className={styles.remember}>
              <input type="checkbox" id="remember" />
              <label
                for="remember"
                onClick={() => {
                  setRemember(true)
                  window.dataLayer.push({
                    event: "GAEvent",
                    event_category: "Age Gate",
                    event_action: "Intercation",
                    event_label: "Recuerdame",
                    interaction: false,
                    component_name: "",
                    element_text: "",
                  })
                }}
              >
                RECORDARME
              </label>
            </div>
          </div>
          <div className={styles.age_text}>
            <p>
              Si sos menor de edad tenés que saber que el consumo de alcohol no
              es bueno para tu salud sobre todo para tu cerebro que se encuentra
              en proceso de desarrollo. Se honesto/a en tu respuesta y accede a la promo.
            </p>
          </div>
          <div className={styles.age_footer}>
            <a
              target="_blank"
              href="/terminosycondiciones.pdf"
              style={{ textDecoration: "none", color: "white" }}
            >
              <p>TÉRMINOS DE USO</p>
            </a>
            <p> | </p>
            <a
              target="_blank"
              href="/politicadeprivacidad.pdf"
              style={{ textDecoration: "none", color: "white" }}
            >
              <p> POLÍTICA DE PRIVACIDAD</p>
            </a>
            <p> | </p>
            <p>CERVECERÍA Y MALTERÍA QUILMES </p>
          </div>
        </div>
      </BackgroundImage>
    </div>
  )
}

export default AgeGate
export const query = graphql`
  query {
    ageImage: file(relativePath: { eq: "ageGateBackgroundNew.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
